/* ==========================================================================
  main.js
========================================================================== */

'use strict';
/* global fitvids, cssVars */

/**
 * Init responsive videos
 */
fitvids();

/**
 * Init CSS vars ponyfill
 */
cssVars({});

/**
 * init humbleScroll library
 * @repo : https://github.com/MathiasOxholm/humbleScroll
 */
const scroll = new HumbleScroll();
